import { ref } from 'vue';

const showToast = ref(false);
const isSuccess = ref(null);
const toastMessage = ref(null);

const triggerToast = (success, message) => {
	if (success) {
		isSuccess.value = true;
	} else {
		isSuccess.value = false;
	}
	toastMessage.value = message;
	showToast.value = true;
	setTimeout(() => {
		showToast.value = false;
		isSuccess.value = null;
		toastMessage.value = null;
	}, 3000);
};

const useToaster = () => {
	return { showToast, isSuccess, toastMessage, triggerToast };
};

export default useToaster;
