import { ref } from 'vue';

// firebase imports
import { db } from '../firebase/config';
import { collection, getDocs } from 'firebase/firestore';

const getCollection = (c, sortby) => {
	const documents = ref(null);

	// collection reference
	const colRef = collection(db, c);

	getDocs(colRef).then((snapshot) => {
		let results = [];
		snapshot.docs.forEach((doc) => {
			// console.log(doc.data());
			results.push({ ...doc.data(), id: doc.id });
		});
		if (sortby && sortby === 'name') {
			results.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
		}
		// console.log(results);
		documents.value = results;
	});

	return { documents };
};

export default getCollection;
