<template>
	<div id="login">
		<transition name="toast">
			<Toast v-if="showToast" :success="isSuccess" :message="toastMessage" />
		</transition>
		<form @submit.prevent="handleLogin" class="card">
			<img class="logo" src="../assets/logo.svg" alt="Southern Guitar Service" />
			<input
				class="input"
				type="email"
				name="email"
				v-model="email"
				required
				placeholder="E-mail cím"
			/>
			<input
				class="input"
				type="password"
				name="password"
				v-model="password"
				required
				placeholder="Jelszó"
			/>
			<button>Bejelentkezés</button>
		</form>
	</div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import useLogin from '../composables/useLogin';

import useToaster from '../composables/useToaster';
import Toast from '../components/Toast.vue';

export default {
	name: 'Login',
	components: { Toast },
	setup() {
		const { showToast, isSuccess, toastMessage, triggerToast } = useToaster();

		const email = ref('');
		const password = ref('');

		const { error, login } = useLogin();
		const router = useRouter();

		const handleLogin = async () => {
			await login(email.value, password.value);

			if (!error.value) {
				router.push({ name: 'Home' });
			} else {
				triggerToast(false, 'Hibás felhasználónév vagy jelszó');
			}
		};

		return { handleLogin, email, password, showToast, isSuccess, toastMessage };
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/mixins';

#login {
	form {
		width: 420px;
		text-align: center;
		box-shadow: var(--module-shadow);

		.logo {
			display: inline-block;
			width: 50%;
			margin-bottom: 2rem;
			@include noselect;
		}
	}
}
</style>
