import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import './assets/reset.css';
import './assets/colors.css';
import './assets/typo.scss';
import './assets/common.css';
import './assets/layout.scss';
import './assets/margin.scss';
import './assets/button.scss';
import './assets/input.scss';
import './assets/animations.css';
import './assets/tooltip.scss';

// firebase imports
import { auth } from './firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

let app;

onAuthStateChanged(auth, () => {
	if (!app) {
		app = createApp(App).use(router).mount('#app');
	}
});
