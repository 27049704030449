<template>
	<transition name="toast">
		<Toast v-if="showToast" :success="isSuccess" :message="toastMessage" />
	</transition>
	<div class="container">
		<div id="info-card" class="card">
			<h5>Infó szöveg</h5>
			<div class="input-btn-group" v-for="message in messages" :key="message.id">
				<input class="input" type="text" v-model="message.text" />
				<button @click="handleUpdate(message)">Mentés</button>
			</div>
		</div>
		<div id="users-card" class="card">
			<div class="card-header">
				<span class="material-symbols-rounded">group</span>
				<span>Ügyfelek száma</span>
			</div>
			<span class="counter">16</span>
		</div>
		<div id="active-card" class="card">
			<div class="card-header">
				<span class="material-symbols-rounded">description</span>
				<span>Aktív munkalapok száma</span>
			</div>
			<span class="counter">8</span>
		</div>
		<div id="closed-card" class="card">
			<div class="card-header">
				<span class="material-symbols-rounded">task</span>
				<span>Lezárt munkalapok száma</span>
			</div>
			<span class="counter">24</span>
		</div>
	</div>
</template>

<script>
// firebase imports
import { db } from '../firebase/config';
import { doc, updateDoc } from 'firebase/firestore';

// composables
import useToaster from '../composables/useToaster';
import getCollection from '../composables/getCollection';

// components
import Toast from '../components/Toast.vue';

export default {
	name: 'Dashboard',
	components: { Toast },
	setup() {
		const { showToast, isSuccess, toastMessage, triggerToast } = useToaster();

		const { documents: messages } = getCollection('info-text');

		const handleUpdate = (message) => {
			// document reference
			const docRef = doc(db, 'info-text', message.id);

			updateDoc(docRef, {
				text: message.text,
			}).then(() => triggerToast(true, 'Sikeres mentés'));
		};

		return { showToast, isSuccess, toastMessage, triggerToast, messages, handleUpdate };
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/mixins';

.container {
	display: grid;
	width: 80%;
	max-width: 1024px;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;
	filter: drop-shadow(var(--module-shadow));

	#info-card {
		grid-column: 1 / span 3;
	}

	#users-card,
	#active-card,
	#closed-card {
		padding: 1.5rem;
		@include noselect;
	}

	/* #active-card {
		color: var(--error);
	}

	#closed-card {
		color: var(--success);
	} */

	.card-header {
		display: flex;
		align-items: center;
		margin-bottom: 0.666rem;

		.material-symbols-rounded {
			font-size: 2rem;
			margin-right: 0.333rem;
		}
	}

	.counter {
		font-size: 5rem;
		font-weight: 600;
		letter-spacing: -0.05em;
	}
}
</style>
