<template>
	<div class="container">
		<div id="search-card" class="card">
			<h5>Ügyfél keresése</h5>
			<div class="input-btn-group">
				<input class="input" type="text" v-model="search" />
				<button @click="handleReset">Keresés törlése</button>
			</div>
		</div>
		<div class="card user-card" v-for="client in filteredClients" :key="client.id">
			<div id="details" class="col">
				<h6 :data-ttip="`${client.birth} | ${client.document}`">
					{{ client.name }}
				</h6>
				<span>{{ client.address }}</span>
				<span>{{ client.phone }}</span>
				<span>
					<a :href="'mailto:' + client.email">{{ client.email }}</a>
				</span>
			</div>
			<div id="active-worksheets" class="col">
				<h6>Aktív munkalapok</h6>
				<span><a>Fender Telester</a></span>
				<span><a>Bigson SG</a></span>
				<span><a>Apeyphone Studio</a></span>
			</div>
			<div id="closed-worksheets" class="col">
				<h6>Lezárt munkalapok</h6>
				<span><a>Fender Stratoster</a></span>
				<span><a>PRS Feri Custom</a></span>
			</div>
			<div id="functions" class="col">
				<a data-ttip="Új munkalap">
					<span class="material-symbols-rounded">add</span>
				</a>
				<a data-ttip="Adatok szerkesztése">
					<span class="material-symbols-rounded">edit_note</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
// libraries
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// composables
import getCollection from '../composables/getCollection';

export default {
	name: 'SearchClients',
	setup() {
		const { documents: clients } = getCollection('clients', 'name');

		const route = useRoute();
		const newUser = ref('');

		if (route.query.user) {
			newUser.value = route.query.user;
		}

		const search = ref('');

		const filteredClients = computed(() => {
			if (clients.value) {
				if (newUser.value && !search.value) {
					return clients.value.filter((client) => client.cid === newUser.value);
				} else if (newUser.value && search.value) {
					return clients.value.filter((client) => {
						return client.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
					});
				} else {
					return clients.value.filter((client) => {
						return client.name.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
					});
				}
			}
		});

		const router = useRouter();

		const handleReset = () => {
			newUser.value = '';
			search.value = '';
			router.push({ name: 'Clients' });
		};

		return { clients, search, filteredClients, handleReset };
	},
};
</script>

<style lang="scss" scoped>
@import '../assets/mixins';

.container {
	display: grid;
	width: 80%;
	max-width: 1024px;
	margin: 5rem auto;
	align-self: flex-start;
	grid-template-columns: 1fr;
	gap: 1rem;
	filter: drop-shadow(var(--module-shadow));

	.user-card {
		display: grid;
		grid-template-columns: 4fr 4fr 4fr 1fr;
		gap: 1rem;

		h6 {
			margin-bottom: 0.5rem;
			font-weight: 600;
			align-self: flex-start;
		}

		span {
			line-height: 1.5;
		}

		.col {
			display: flex;
			padding-right: calc(1rem - 3px);
			flex-direction: column;
			border-right: 3px solid var(--main-background);
		}

		#active-worksheets {
			@include noselect;
		}

		#closed-worksheets {
			@include noselect;

			span a {
				color: #999;
			}
		}

		#functions {
			padding: 0;
			flex-direction: row;
			align-items: center;
			justify-content: space-evenly;
			border: 0;

			.material-symbols-rounded {
				font-size: 41px;
				line-height: 1;
				@include noselect;
			}
		}
	}
}
</style>
